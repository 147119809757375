import Popup from "../core/Popup";
import { invertHexRgb } from "../core/extras/helpers";
import { optimizeUnit } from "../core/extras/helpers_data";

export type VoltagePopupContent = {
    voltage: number;
};

/**
 * Popup that displays when the cursor hovers a zone on {@link VoltageLayer}
 */
export default class VoltagePopup extends Popup<VoltagePopupContent> {
    /**
     * Updates the content of current popup
     *
     * @param content details for the popup
     */
    public updateContent(content: VoltagePopupContent): void {
        if (content.voltage === null) {
            this._container.style.visibility = "hidden";
        } else {
            this._container.style.visibility = "visible";
        }

        const rgb = "#777";

        this._container.style.backgroundColor = rgb;
        this._container.style.color = invertHexRgb(rgb, true);
        this._container.innerHTML = `<b>Voltage</b>: ${optimizeUnit(
            content.voltage,
            "V"
        )}`;
    }

    protected __draw__(content: VoltagePopupContent): void {
        this.updateContent(content);
    }
}
