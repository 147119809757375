import Cell from "../../core/Cell";

/**
 * Returns min and max cells compared by its non-fixed axis
 *
 * @param c1 first cell to compare
 * @param c2 second cell to compare
 */
export function minmaxfixCell(c1: Cell, c2: Cell): [Cell, Cell] {
  const isFixedX = c1.idx.x === c2.idx.x;
  const isFixedY = c1.idx.y === c2.idx.y;

  if (isFixedX) {
    return c1.idx.y < c2.idx.y ? [c1, c2] : [c2, c1];
  }

  if (isFixedY) {
    return c1.idx.x < c2.idx.x ? [c1, c2] : [c2, c1];
  }

  throw Error("Points are placed on different axes");
}
