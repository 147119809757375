import { CellRole, Domain } from "./types";

const SUFFIXES: { [key: number]: [string, string] } = {
    10e-25: ["y", "yocto"],
    10e-22: ["z", "zepto"],
    10e-19: ["a", "atto"],
    10e-16: ["f", "femto"],
    10e-13: ["p", "pico"], // 0.0000000001
    10e-10: ["n", "nano"], // 0.0000001
    10e-7: ["μ", "micro"], // 0.0001
    10e-4: ["m", "milli"], // 0.001
    1: ["", ""],
    10e2: ["k", "kilo"], // 1000
    10e5: ["m", "mega"], // 1000000
    10e8: ["G", "giga"], // 1000000000
    10e11: ["T", "tera"],
    10e14: ["P", "peta"],
    10e17: ["E", "exa"],
    10e20: ["Z", "zetta"],
    10e21: ["Y", "yotta"]
};

// 10e-6 < 10e-24 = false

export function optimizeUnit(value: number, unit: string, round: number = 5) {
    if (value === 0) return `${value} ${unit}`;

    let key = Number(
        Object.keys(SUFFIXES).reduce((prev, curr) =>
            Math.abs(Number(curr) - value) < Math.abs(Number(prev) - value)
                ? curr
                : prev
        )
    );

    const factor = Math.pow(10, round);

    return `${Math.round((value / key) * factor) / factor} ${
        SUFFIXES[key][0]
    }${unit}`;
}

export function isDomainAnalog(domain: Domain) {
    return domain.props.role === CellRole.Analog;
}

export function isDomainMinus(domain: Domain) {
    return domain.props.role === CellRole.Minus;
}

export function isDomainPlus(domain: Domain) {
    return domain.props.role === CellRole.Plus;
}
