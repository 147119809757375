import Presenter, { on, restore } from "../../core/base/Presenter";
import LessonModel from "../../models/lesson/LessonModel";
import ProgressModel, {
  ExerciseRunEvent,
  LessonRunEvent
} from "../../models/lesson/ProgressModel";
import CodeModel, {
  CodeVariableUpdateEvent
} from "../../models/common/CodeModel";
import VariableView from "../../views/common/VariableView";

export default class VariablesLessonPresenter extends Presenter<VariableView.VariableView> {
  private code: CodeModel;
  private lesson: LessonModel;
  private progress: ProgressModel;

  getInitialProps(): any {
    this.code = this.getModel(CodeModel);
    this.lesson = this.getModel(LessonModel);
    this.progress = this.getModel(ProgressModel);

    return {
      variables: this.getVariableValues()
    };
  }

  @restore()
  @on(ExerciseRunEvent, CodeVariableUpdateEvent)
  private async showVariableValues() {
    this.setViewProps({
      variables: this.getVariableValues()
    });
  }

  @restore()
  @on(LessonRunEvent, ExerciseRunEvent)
  private async defineVariables() {
    const vardefs = this.getVariableDefinitions();

    this.code.setVariableDefinitions(vardefs);
  }

  private getVariableValues() {
    const vardefs = this.getVariableDefinitions();
    const variables = this.code.getState().variables;

    return (
      vardefs?.map((variable) => {
        return {
          name: variable.name,
          type: variable.type,
          value:
            variables[variable.name] != null
              ? variables[variable.name]
              : variable.initial_value
        };
      }) || []
    );
  }

  private getVariableDefinitions() {
    const [mission_idx, exercise_idx] = this.progress.getOpenedExerciseIndex();

    const exercise = this.lesson.getExercise(mission_idx, exercise_idx);

    const code = exercise.module_settings.code;

    return code?.variables;
  }
}
