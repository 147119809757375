import { VAR_DEFAULT } from "./constants";

const GLOBALS = { VARS: [] };

export function setGlobals(globals) {
  for (const [key, value] of Object.entries(globals)) {
    GLOBALS[key] = value;
  }
}

export function getGlobals() {
  return GLOBALS;
}

export function getVars() {
  const vars = getGlobals().VARS;

  // remove duplicates, format ['desc', 'name']
  const unique = vars.reduce(
    (prev, curr) =>
      prev.findIndex((v) => v[1] === curr[1]) === -1 ? [...prev, curr] : prev,
    []
  );

  return unique.length ? unique : [VAR_DEFAULT];
}
