require("./json");

import MathJSONGenerators from "./math/generators";
import LogicJSONGenerators from "./logic/generators";
import ControlsJSONGenerators from "./controls/generators";
import EventJSONGenerators from "./event/generators";
import StripJSONGenerators from "./strip/generators";
import ArduinoJSONGenerators from "./arduino/generators";
import VarJSONGenerators from "./var/generators";

const JSONGenerators = {
  ...MathJSONGenerators,
  ...LogicJSONGenerators,
  ...ControlsJSONGenerators,
  ...EventJSONGenerators,
  ...StripJSONGenerators,
  ...ArduinoJSONGenerators,
  ...VarJSONGenerators
};

export default JSONGenerators;
